import {
  EVENTS_APP_ID,
  EVENT_FILTER_TYPE,
  ExperimentNames,
  FEATURED_EVENT_DISPLAY_STRATEGY,
  LIST_DISPLAY_STRATEGY,
  WIDGET_TYPE,
  isRtlLanguage,
  sortEvents,
} from '@wix/wix-events-commons-statics'
import {ControllerParams, CreateControllerFn, ExperimentsBag} from '@wix/yoshi-flow-editor'
import {PreloadedState, Store, bindActionCreators} from 'redux'
import {setBaseEnvironment, setFormFactor} from '../../commons/actions/environment'
import {watchInstance} from '../../commons/actions/instance'
import {updateSiteSettings} from '../../commons/actions/site-settings'
import {createUouBiMiddlewareWithBiParams} from '../../commons/bi/bi'
import {BiParams} from '../../commons/bi/interfaces'
import {EMPTY_CATEGORY_FILTER} from '../../commons/constants/settings'
import {UserRole} from '../../commons/enums'
import {isResponsiveEditor} from '../../commons/selectors/environment'
import {decodeInstance} from '../../commons/selectors/instance'
import {getInstalledAppsState} from '../../commons/services/installed-apps'
import {getMultilingualInitialState} from '../../commons/services/multilingual'
import {getQueryParams} from '../../commons/services/navigation'
import {createReduxStore} from '../../commons/utils/store'
import {getLanguage, getPageInfo, isEditor} from '../../commons/utils/wix-code-api'
import settingsParams from './settingsParams'
import {CommonWidgetDataParams} from './shared-api'
import {getDefaultNumbersAndBooleans} from './stylesParams'
import {
  addCalendarMonth,
  closeMonthlyCalendarEvent,
  closeMonthlyCalendarPopup,
  openMonthlyCalendarEvent,
  openMonthlyCalendarPopup,
  resetCalendar,
  subCalendarMonth,
} from './Widget/actions/calendar-layout'
import {updateStyleParams, updateStyles} from './Widget/actions/component'
import {shareEvent} from './Widget/actions/event'
import {loadEventsPage, reloadEvents} from './Widget/actions/events'
import {closeListLayoutItems, openListLayoutItem, widgetLoaded} from './Widget/actions/layout'
import {addLoginListener, loadMembersForEvents, openMembersModal} from './Widget/actions/members'
import {navigateToPage, onLinkNavigatedToPage} from './Widget/actions/navigate-to-page'
import {updateSettings} from './Widget/actions/sdk'
import {setVeloCategoryFilter} from './Widget/actions/velo-overrides'
import * as eventsUou from './Widget/bi/uou-bi-events-map'
import {AppProps} from './Widget/components/app/interfaces'
import {datesMiddleware} from './Widget/middlewares/date'
import reducers from './Widget/reducers'
import {getDynamicCategoryId} from './Widget/selectors/velo-overrides'
import {renderSEOTags} from './Widget/services/seo'
import {Actions, State, StoreExtraArgs} from './Widget/types/state'
import {userEventsLogger} from './Widget/user-events-logger'
import {Api} from './Widget/utils/api'

const createWidgetController: CreateControllerFn = async (controllerParams: ControllerParams) => {
  const experiments = controllerParams.flowAPI.experiments.all()
  const serverApi = new Api(controllerParams)
  const store: any = await createStore({controller: controllerParams, serverApi, experiments})

  return {
    pageReady: async () => {
      return pageReady({controllerParams, store, serverApi})
    },
    exports: () => ({
      set categoryId(id: string) {
        store.dispatch(setVeloCategoryFilter(id))
        store.dispatch(reloadEvents())
      },
      get categoryId() {
        return getDynamicCategoryId(store.getState(), controllerParams.flowAPI)
      },
    }),
    updateAppSettings: async (_$w, action) => {
      if (store) {
        setTimeout(() => {
          store.dispatch(updateSettings(action as any))
        }, 100)
      }
    },
    updateConfig: (_$w, newConfig) => {
      store.dispatch(
        updateStyles({
          numbers: newConfig.style.styleParams.numbers,
          booleans: newConfig.style.styleParams.booleans,
        }),
      )
    },
  }
}

interface PageReadyParams {
  controllerParams: ControllerParams
  store: any
  serverApi: Api
}

const pageReady = async ({store, serverApi, controllerParams}: PageReadyParams) => {
  const {controllerConfig: controller} = controllerParams
  const language = getLanguage(controller.wixCodeApi)

  const [pageInfo, pageUrl] = await Promise.all([
    getPageInfo(controller.wixCodeApi),
    controller.wixCodeApi.site.getSectionUrl({
      sectionId: 'events',
      appDefinitionId: EVENTS_APP_ID,
    }),
  ])
  const state = store.getState()

  const props: Partial<AppProps> = {
    pageUrl,
    baseUrl: controller.wixCodeApi.location.baseUrl,
    queryParams: getQueryParams(controller.wixCodeApi),
    pageInfo,
    state,
    actions: exportedActions({
      store,
    }),
    isRTL: isRtlLanguage(language),
    // @ts-expect-error
    fitToContentHeight: true,
  }

  if (state.membersEnabled) {
    serverApi.onLogin(() => {
      store.dispatch(loadMembersForEvents())
    })
  }

  if (controllerParams.flowAPI.experiments.enabled(ExperimentNames.WidgetSEOTags)) {
    await renderSEOTags({wixCodeApi: controller.wixCodeApi, state, pageUrl: pageUrl.url})
  }

  controller.setProps(props)
  subscribeToStateChanges(controllerParams, store)
}

interface CreateStoreParams {
  controller: ControllerParams
  serverApi: Api
  experiments: ExperimentsBag
}

const createStore = async ({controller, serverApi, experiments}: CreateStoreParams) => {
  const {
    controllerConfig: {wixCodeApi, platformAPIs, appParams, compId, config},
    flowAPI,
  } = controller

  let initialData: Awaited<ReturnType<typeof getInitialData>>
  const warmupDataKey = `widget${controller.controllerConfig.compId}`

  if (flowAPI.experiments.enabled(ExperimentNames.UseWarmupState) && flowAPI.environment.isSSR) {
    initialData = await getInitialData({serverApi, controller, experiments})
    wixCodeApi.window.warmupData.set(warmupDataKey, initialData)
  } else {
    initialData =
      wixCodeApi.window.warmupData.get(warmupDataKey) ?? (await getInitialData({serverApi, controller, experiments}))
  }

  const biParams: BiParams = {
    wixCodeApi,
    flowAPI,
    platformAPIs,
    appParams,
    compId,
  }

  const biMiddleware = [createUouBiMiddlewareWithBiParams(biParams, eventsUou)]

  const userEventsLoggerMiddleware = userEventsLogger({wixCodeApi})

  const store = createReduxStore<State, StoreExtraArgs>({
    reducers,
    initialData,
    extraArguments: {
      serverApi,
      wixCodeApi,
      compId,
      flowAPI: controller.flowAPI,
    },
    middleware: [...biMiddleware, userEventsLoggerMiddleware, datesMiddleware],
  })

  store.dispatch(updateStyleParams(config.style.styleParams))
  store.dispatch(addLoginListener() as any)
  await store.dispatch(setBaseEnvironment() as any)

  watchInstance(controller.controllerConfig, store.dispatch)

  return store
}

const subscribeToStateChanges = (controllerParams: ControllerParams, store: Store) => {
  const onStateChange = () => {
    const state: State = store.getState()
    controllerParams.controllerConfig.setProps({
      state,
    })
  }

  store.subscribe(onStateChange)
}

interface GetInitialDataParams {
  serverApi: Api
  experiments: ExperimentsBag
  controller: ControllerParams
}

const getInitialData = async ({serverApi, controller}: GetInitialDataParams): Promise<PreloadedState<State>> => {
  const {wixCodeApi, appParams, config: controllerConfig} = controller.controllerConfig
  const {flowAPI} = controller
  const updatedDisplayListEventsPanelEnabled = flowAPI.experiments.enabled(
    ExperimentNames.UpdatedDisplayListEventsPanel,
  )

  const filterType = flowAPI.settings.get(settingsParams.filterType) as number
  const sortOrder = flowAPI.settings.get(settingsParams.sortOrder) as number
  const categoryId = flowAPI.settings.get(settingsParams.categoryId) as string
  const limit = flowAPI.settings.get(settingsParams.eventsPerPage) as number

  const listDisplayStrategy = flowAPI.settings.get(settingsParams.listDisplayStrategy) as number
  const listStatusFilter = flowAPI.settings.get(settingsParams.listStatusFilter) as number

  const featuredWidget = flowAPI.settings.get(settingsParams.featuredEvent) as boolean
  const featuredCategoryId = flowAPI.settings.get(settingsParams.featuredCategoryId) as string
  const featuredEventId = flowAPI.settings.get(settingsParams.featuredEventId) as string
  const featuredDisplayStrategy = flowAPI.settings.get(
    settingsParams.featuredEventDisplayStrategy,
  ) as FEATURED_EVENT_DISPLAY_STRATEGY

  const installedApps = await getInstalledAppsState(wixCodeApi)
  const {eventsInMembersInstalled, paidPlans: paidPlansInstalled} = installedApps

  const instance = appParams.instance
  const decodedInstance = decodeInstance(instance)
  const isTemplate = decodedInstance.siteIsTemplate
  const responsive = isResponsiveEditor(controllerConfig)
  const showcase = isTemplate || isEditor(wixCodeApi)
  const {widgetType, listLayout, recurringFilter} = controllerConfig.style.styleParams.numbers
  const {compId, language, locale, tz, viewMode} = serverApi
  const useGuestsService = flowAPI.experiments.enabled(ExperimentNames.UseGuestsService)
  const newClassicEvents = flowAPI.experiments.enabled(ExperimentNames.NewClassicEvents)
  const editor = isEditor(wixCodeApi)

  const {listShowMembers = true, showMembers = true} = controllerConfig.style.styleParams.booleans
  const singleWidgetType = widgetType === WIDGET_TYPE.SINGLE
  const downloadMembers = eventsInMembersInstalled && (singleWidgetType ? showMembers : listShowMembers)

  const commonArgs: CommonWidgetDataParams = {
    members: downloadMembers,
    paidPlans: paidPlansInstalled,
    responsive,
    language,
    locale,
    tz,
    viewMode,
    membersV2: useGuestsService,
    newClassicEvents,
  }

  const statusFilter = updatedDisplayListEventsPanelEnabled
    ? listDisplayStrategy === LIST_DISPLAY_STRATEGY.CUSTOM
      ? EVENT_FILTER_TYPE.MANUAL
      : listStatusFilter
    : filterType

  const categoryIdFilter =
    (updatedDisplayListEventsPanelEnabled && listDisplayStrategy !== LIST_DISPLAY_STRATEGY.CATEGORY) ||
    categoryId === EMPTY_CATEGORY_FILTER
      ? undefined
      : categoryId

  const {component, siteSettings, demoEvents, hasMoreEvents, dates} = featuredWidget
    ? await serverApi.getFeaturedWidgetData({
        ...commonArgs,
        eventId: featuredEventId,
        categoryId: featuredCategoryId,
        manuallySelected: featuredDisplayStrategy === FEATURED_EVENT_DISPLAY_STRATEGY.CUSTOM,
      })
    : await serverApi.getWidgetData({
        showcase,
        widgetType,
        listLayout,
        recurringFilter,
        categoryId: categoryIdFilter,
        noCategoryIdFallback: categoryIdFilter === EMPTY_CATEGORY_FILTER,
        compId,
        filterType: statusFilter,
        sortOrder: filterType === EVENT_FILTER_TYPE.MANUAL ? sortOrder : undefined,
        limit: editor ? 20 : limit,
        ...commonArgs,
      })

  const settings = getDefaultNumbersAndBooleans({
    isMobile: flowAPI.environment.isMobile,
    isRTL: flowAPI.environment.isRTL,
  })

  const events = component.featuredEvent ? [component.featuredEvent] : component.events ?? []

  return {
    events: {
      events: filterType === EVENT_FILTER_TYPE.MANUAL ? events : sortEvents(events),
      hasMore: hasMoreEvents,
      moreLoading: false,
      filterType,
    },
    siteSettings,
    demoEvents,
    multilingual: getMultilingualInitialState(wixCodeApi),
    component: {
      id: compId,
      settings,
    },
    memberLoggedIn: wixCodeApi.user.currentUser.loggedIn,
    owner: wixCodeApi.user.currentUser.role === UserRole.ADMIN,
    instance: {
      instance,
      ...decodedInstance,
    },
    calendarLayout: {
      ...(dates.calendar ?? {}),
      loadedPages: dates.calendar?.referenceDate ? [dates.calendar.referenceDate] : [],
    },
    dates,
    installedApps,
  } as PreloadedState<State>
}

const exportedActions = ({store}) => {
  const dispatchActions = {
    addCalendarMonth,
    subCalendarMonth,
    resetCalendar,
    updateSiteSettings,
    navigateToPage,
    onLinkNavigatedToPage,
    updateStyles,
    updateSettings,
    setBaseEnvironment,
    openListLayoutItem,
    closeListLayoutItems,
    openMonthlyCalendarPopup,
    closeMonthlyCalendarPopup,
    openMonthlyCalendarEvent,
    closeMonthlyCalendarEvent,
    widgetLoaded,
    openMembersModal,
    shareEvent,
    setFormFactor,
    loadEventsPage,
  }

  const actions: Actions = {
    ...bindActionCreators(dispatchActions, store.dispatch),
  }

  return actions
}

export default createWidgetController
